import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import BoardCard from "../Components/BoardCard";

import DailogNew from "../Components/DailogNew";
import Notification from "../Components/Notification";
import Search from "../Components/searchComponent";
import ShareBoard from "../Containers/ShareBoard";
import { BoardServices } from "../Services/Board";
import { activeMenuTab } from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import HelpModal from "./HelpModal";
import DynamicTableComponent from "../Components/DynamicTableComponent";
import { NavLink } from "reactstrap";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import moment from "moment";
import permissionCheck from "../lib/permissionCheck";

import decodeJWT from "../lib/decodeJWT";
import DetailsDateTimeFormatter from "../Utils/DetailsDateTimeFormatter";
import {
  boards,
  boardDetails,
  boardContent,
  boardGeneralComments,
  boardContentComments,
  boardContentDetails,
  boardType,
} from "../Store/Actions/Board";
let tableHeader = [
  {
    id: "name",
    title: "Board Name",
    sortable: true,
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "pins",
    title: "No of Pins",
    sortable: false,
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Updated",
    sortable: true,
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "last_updated_at",
    title: "Content Last Updated",
    sortable: false,
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "sharewith",
    title: "Shared With",
    sortable: false,
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

const showActions = false;
class MyBoards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      rowDatacards: [],
      lastrowDatacardsCount: 0,
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      boardModal: false,
      boardModalHeader: "",
      boardName: "",
      formErrors: {},
      apiError: null,
      boardId: null,
      expanded: false,
      count: 0,
      clickgrid: true,
      inputDisabled: false,
      filter: [
        {
          id: "all",
          name: "All",
          is_selected: true,
        },
        {
          id: "owner",
          name: "My Boards",
          is_selected: false,
        },
        {
          id: "shared",
          name: "Shared Boards",
          is_selected: false,
        },
      ],
      navTabs: [],
    };
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getBoards()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getBoards()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getBoards());
  };
  handleExpandLess = () => {
    let oldData = this.state.rowDatacards;
    if (this.state.lastrowDatacardsCount > 0) {
      oldData.splice(-this.state.lastrowDatacardsCount);
      this.setState({ lastrowDatacardsCount: 0 });
    } else {
      oldData.splice(-8);
    }
    this.setState({ rowDatacards: oldData, pageNo: this.state.pageNo - 1 });
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getBoards()
    );
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getBoards();
    });
  };

  createBoard = (boardId) => {
    this.setState({
      boardModal: !this.state.boardModal,
      boardName: "",
      boardModalHeader:
        boardId === null ? (
          <>
            <div className="d-flex align-items-center">
              <span class="material-icons mr-2 ml-n1">add</span>
              {constants.APPLICATION_ROUTE.MY_BOARDS.CREATE.HEADER_TITLE}
            </div>
          </>
        ) : (
          constants.APPLICATION_ROUTE.MY_BOARDS.EDIT.HEADER_TITLE
        ),
      apiError: null,
      boardId: boardId,
      formErrors: {},
    });
  };
  editHandler = (e, board) => {
    e.stopPropagation();
    this.setState({
      boardModal: !this.state.boardModal,
      boardName: board.name,
      boardModalHeader:
        board.id === null ? (
          constants.APPLICATION_ROUTE.MY_BOARDS.CREATE.HEADER_TITLE
        ) : (
          <>
            <div className="d-flex align-items-center">
              <span class="material-icons-outlined mr-2 icon-fs-22">edit</span>
              {constants.APPLICATION_ROUTE.MY_BOARDS.EDIT.HEADER_TITLE}
            </div>
          </>
        ),
      apiError: null,
      boardId: board.id,
    });
  };
  detailsPageHandler = (board) => {
    this.props.history &&
      this.props.history.push({
        //for unit testing in jest (this.props.history &&) Use
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.MY_BOARDS.DASHBOARD.ROUTE,
          { id: board.id }
        ),
      });
  };
  closeBoardModal = () => {
    this.setState({
      boardModal: false,
    });
  };
  onBoardNameChangeHandler = (e) => {
    this.setState(
      {
        boardName: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.clickgrid != this.state.clickgrid) {
      this.getBoards();
    }
  }
  componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME);
    let params = queryString.parse(this.props.location.search);
    let filters = this.state.filter;
    filters.map(
      (m) =>
        (m.is_selected =
          (params.filter && m.id === params.filter) ||
          (!params.filter && m.id === "all"))
    );
    let obj = {
      name: params.search_value ? params.search_value : "",
      filter: filters,
    };
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    if (this.state.clickgrid) {
      obj.sortColumn = "updated_at";
      obj.sortOrder = "desc";
    }
    this.setState(
      {
        obj,
        navTabs:
          tokenDetails.role.short_name === "lead"
            ? [
                {
                  id: "all",
                  name: "All Boards",
                  is_selected: true,
                },
                {
                  id: "my",
                  name: "My Boards",
                  is_selected: false,
                },
                {
                  id: "enterprise",
                  name: "Enterprise Boards",
                  is_selected: false,
                },
                {
                  id: "tbri",
                  name: "TBR Boards",
                  is_selected: false,
                },
                {
                  id: "shared",
                  name: "Shared Boards",
                  is_selected: false,
                },
              ]
            : tokenDetails.role.short_name === "member"
            ? [
                {
                  id: "all",
                  name: "All Boards",
                  is_selected: true,
                },
                {
                  id: "enterprise",
                  name: "Enterprise Boards",
                  is_selected: false,
                },
                {
                  id: "tbri",
                  name: "TBR Boards",
                  is_selected: false,
                },
                {
                  id: "shared",
                  name: "Shared Boards",
                  is_selected: false,
                },
              ]
            : [
                {
                  id: "all",
                  name: "All Boards",
                  is_selected: true,
                },
                {
                  id: "my",
                  name: "My Boards",
                  is_selected: false,
                },
                {
                  id: "tbri",
                  name: "TBR Boards",
                  is_selected: false,
                },
                {
                  id: "shared",
                  name: "Shared Boards",
                  is_selected: false,
                },
              ],
      },
      () => {
        this.getBoards();
      }
    );
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE);
  }
  handleSearch = () => {
    this.setState(
      {
        pageNo: 1,
        rowData: [],
        rowDatacards: [],
      },
      () => {
        this.getBoards();
      }
    );
  };
  removeBoardConfirmationHandler = (e, data) => {
    e.stopPropagation();
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {data.is_published
              ? constants.MY_BOARDS.LIST.PUBLISHED_REMOVE_BOARD_CONFIRMATION
              : constants.MY_BOARDS.LIST.REMOVE_BOARD_CONFIRMATION}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_board", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeBoard = (boardId) => {
    BoardServices.removeBoard(boardId)
      .then((res) => {
        let queryParams = "";
        if (this.state.sortOrder) {
          queryParams +=
            "&sort_order=" +
            this.state.sortOrder +
            "&sort_column=" +
            this.state.sortColumn;
        }
        if (this.state.name !== "") {
          queryParams += "&search_column=name&search_value=" + this.state.name;
        }
        if (this.state.filter.find((f) => f.is_selected === true).id !== "all")
          queryParams +=
            "&type=" + this.state.filter.find((f) => f.is_selected === true).id;
        this.changeRoute();
        let url = "/boards?page=" + this.state.pageNo + "&page_size=9";

        if (this.state.rowDatacards.length < this.state.count) {
          BoardServices.getBoards(`${url}` + queryParams)
            .then((res) => {
              let boardData = res.data.data;
              let meta = res.data.meta;
              let boardData2 = boardData.map((b) => ({
                ...b,
                showActions: false,
              }));

              boardData = boardData.map((b) => {
                return {
                  id: b.id,
                  name: (
                    <NavLink
                      className={`p-0`}
                      onClick={() => this.detailsPageHandler(b)}
                    >
                      <label
                        className={`mb-0`}
                        style={{
                          color: "#046CD9",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                      >
                        {b.name}
                      </label>
                    </NavLink>
                  ),
                  pins: b.total_pinned_content_count,
                  lastupdate: DetailsDateTimeFormatter(
                    b.last_updated_at,
                    false
                  ),
                  actions: this.getActions(b),
                  sharewith: this.getsharewith(b),
                };
              });
              this.updateBoardLocally(constants.MY_BOARDS.DELETE_BOARD, {
                boardId,
              });
              let oldData = this.state.rowDatacards;
              oldData.splice(-7);
              this.setState({
                rowData: boardData,
                rowDatacards: [...oldData, ...boardData2],
                lastrowDatacardsCount:
                  boardData2.length < 9 ? boardData2.length : 0,
                boardModal: false,
                dailogModal: false,
                count: meta.total_count,
                inputDisabled: false,
              });
            })

            .catch((error) => {
              this.setState({
                rowData: [],
                rowDatacards: [],
                count: 0,
                inputDisabled: false,
              });

              Toast(
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
                "error"
              );
            });
        } else {
          this.updateBoardLocally(constants.MY_BOARDS.DELETE_BOARD, {
            boardId,
          });
          let oldData = this.state.rowDatacards;
          oldData.slice(-this.state.lastrowDatacardsCount + 1);
          this.setState({
            rowDataCards: [...oldData],
            count: this.state.count - 1,
            pageNo:
              this.state.lastrowDatacardsCount === 1
                ? this.state.pageNo - 1
                : this.state.pageNo,
            lastrowDatacardsCount: this.state.lastrowDatacardsCount - 1,
          });
        }
        Toast("Board is deleted successfully.", "success");
      })
      .catch((error) => {
        this.getBoards();

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_board":
        this.removeBoard(config.id);
        break;
      default:
        break;
    }
  };
  dateformatChanger = (input) => {
    let dateobj = new Date(input);
    let momentobj = new moment(dateobj);
    let old_date = momentobj.format("DD-MM-YYYY");
    return old_date;
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  getActions = (data) => {
    let board = data;
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let aclPermissionKey = constants.ACL.PERMISSION_KEY;
    let aclResource = constants.ACL.MY_BOARDS_RESOURCE;
    if (
      data.type === "shared" &&
      data.mode != null &&
      data.mode != 0 &&
      data.mode.short_name != undefined
    ) {
      switch (data.mode.short_name) {
        case constants.MY_BOARDS_SHARE_MODE.EDIT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_EDIT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
        case constants.MY_BOARDS_SHARE_MODE.COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_COMMENT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW_COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW_COMMENT;
          break;
        default:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
      }
    }

    let actionsEnabled = permissionCheck(
      aclPermissionKey,
      aclResource.TITLE,
      aclResource.ACTIONS.PIN
    );
    let detailsPageAccess = permissionCheck(
      aclPermissionKey,
      aclResource.TITLE,
      aclResource.ACTIONS.PIN
    );
    let isShareAccess = permissionCheck(
      aclPermissionKey,
      aclResource.TITLE,
      aclResource.ACTIONS.PIN
    );

    return (
      <>
        <div className="d-inline-flex pl-1">
          {actionsEnabled &&
          this.findAsActions(
            board.owner,
            board.shared_users,
            tokenDetails.user?.id
          ) ? (
            <div className="actions_board  d-flex">
              <ul className="d-flex align-items-center w-100">
                <label className="custom-switch-checkbox mr-4">
                  <input
                    type="checkbox"
                    checked={data.display_comments === 1}
                  />
                  <span
                    className="slider round"
                    onClick={(e) => {
                      this.enableDisableCommentsHandler(e, data);
                    }}
                  ></span>
                </label>

                <AclAction
                  type={aclPermissionKey}
                  entity={aclResource.TITLE}
                  action={aclResource.ACTIONS.SHARE}
                >
                  <li
                    className="d-inline-flex cursor-pointer mr-4"
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    onClick={(e) => {
                      this.openShareBoardModal(e, data);
                    }}
                  >
                    <span className="material-icons-outlined text-blue-clr icon-fs-20">
                      share
                    </span>
                  </li>
                </AclAction>
                <AclAction
                  type={aclPermissionKey}
                  entity={aclResource.TITLE}
                  action={aclResource.ACTIONS.EDIT}
                >
                  <li
                    className="d-inline-flex cursor-pointer mr-4"
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    onClick={(e) => {
                      this.editHandler(e, data);
                    }}
                  >
                    <span className="material-icons-outlined text-blue-clr icon-fs-20">
                      edit
                    </span>
                  </li>
                </AclAction>
                <AclAction
                  type={aclPermissionKey}
                  entity={aclResource.TITLE}
                  action={aclResource.ACTIONS.DELETE}
                >
                  <li
                    className="d-inline-flex cursor-pointer mr-4"
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    onClick={(e) => {
                      this.removeBoardConfirmationHandler(e, data);
                    }}
                  >
                    <span className="material-icons-outlined error-clr icon-fs-20">
                      delete
                    </span>
                  </li>
                </AclAction>
              </ul>
            </div>
          ) : (
            "-"
          )}
        </div>
      </>
    );
  };
  getsharewith = (data) => {
    let aclPermissionKey = constants.ACL.PERMISSION_KEY;
    let aclResource = constants.ACL.MY_BOARDS_RESOURCE;
    if (
      data.type === "shared" &&
      data.mode != null &&
      data.mode != 0 &&
      data.mode.short_name != undefined
    ) {
      switch (data.mode.short_name) {
        case constants.MY_BOARDS_SHARE_MODE.EDIT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_EDIT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
        case constants.MY_BOARDS_SHARE_MODE.COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_COMMENT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW_COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW_COMMENT;
          break;
        default:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
      }
    }
    let isShareAccess = permissionCheck(
      aclPermissionKey,
      aclResource.TITLE,
      aclResource.ACTIONS.SHARE
    );
    return (
      <>
        {!isShareAccess && <span></span>}
        {data.type === "owner" && data.shared_users.length > 0 ? (
          <p className="align-items-center d-inline-flex share-info mb-0 ">
            {data.is_shared_with_all && (
              <b
                className="ml-1"
                onClick={(e) => {
                  isShareAccess && this.shareHandler(e, data);
                }}
              >
                ALL at My Company
              </b>
            )}
            {data.shared_users && data.shared_users.length > 0 && (
              <span
                className="d-flex"
                onClick={(e) => {
                  isShareAccess && this.shareHandler(e, data);
                }}
              >
                {data.shared_users.slice(0, 3).map((s, i) => (
                  <>
                    <span
                      class="material-icons-round icon-fs-20 mr-4"
                      key={i}
                      aria-hidden="true"
                      data-for={`tooltip-${Slugify(s.email)}`}
                      data-tip={`${s.first_name} ${s.last_name} (${s.email})`}
                      data-iscapture="true"
                    >
                      person
                    </span>
                    <CustomReactTooltip
                      id={`tooltip-${Slugify(s.email)}`}
                      multiline={true}
                      place="right"
                    />
                  </>
                ))}
              </span>
            )}
          </p>
        ) : (
          "-"
        )}
      </>
    );
  };
  getBoards = () => {
    let queryParams = "";
    if (this.state.sortOrder && this.state.sortColumn != "") {
      queryParams +=
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }

    if (this.state.navTabs.find((f) => f.is_selected === true).id !== "") {
      queryParams +=
        "&board_type=" +
        this.state.navTabs.find((f) => f.is_selected === true).id;
    }

    this.changeRoute();
    let url = !this.state.clickgrid
      ? "/boards?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize
      : "/boards?page=" + this.state.pageNo + "&page_size=9";

    BoardServices.getBoards(`${url}` + queryParams)
      .then((res) => {
        let boardData = res.data.data;

        let meta = res.data.meta;
        let boardData2 = boardData.map((b) => ({ ...b, showActions: false }));

        boardData = boardData.map((b) => {
          return {
            id: b.id,
            name: (
              <NavLink
                className={`p-0`}
                onClick={() => this.detailsPageHandler(b)}
              >
                <label
                  className={`mb-0`}
                  style={{
                    color: "#046CD9",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  {b.name}
                </label>
              </NavLink>
            ),
            pins: (
              <p
                className={`mb-0`}
                style={{
                  paddingLeft: "15px",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {b.total_pinned_content_count}
              </p>
            ),
            updated_at: DetailsDateTimeFormatter(b.updated_at, false),
            last_updated_at: DetailsDateTimeFormatter(b.last_updated_at, false),
            actions: this.getActions(b),
            sharewith: this.getsharewith(b),
          };
        });
        const getData = (length, pageNo) => {
          if (length || pageNo === 1) return [...boardData2];
          return [...this.state.rowDatacards, ...boardData2];
        };
        this.setState(
          {
            rowData: boardData,
            rowDatacards: getData(this.state.name.length, this.state.pageNo),
            lastrowDatacardsCount: boardData2.length < 9 ? boardData.length : 0,
            boardModal: false,
            dailogModal: false,
            count: meta.total_count,
            inputDisabled: false,
          },
          () => {
            this.props.boardType(
              this.state.navTabs.find((f) => f.is_selected === true).id
            );
          }
        );
      })
      .catch((error) => {
        this.setState({
          rowData: [],
          rowDatacards: [],
          count: 0,
          inputDisabled: false,
        });

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleInput = (e) => {
    this.setState(
      { name: e.target.value, inputDisabled: e.target.value.length >= 3 },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER:
        this.props.history &&
          this.props.history.push({
            //for unit testing in jest (this.props.history &&) Use
            pathname: formatRoute(
              constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE,
              { clientId: data.client.id, id: data.id }
            ),
          });
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS:
        this.toggleClientUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER:
        this.removeClientUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .RESEND_EMAIL_VERIFICATION:
        this.resendEmailVerification(data);
        break;
      default:
        break;
    }
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder && this.state.sortColumn != "") {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    if (!this.state.clickgrid) {
      this.props.history &&
        this.props.history.push({
          //for unit testing in jest (this.props.history &&) Use
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE,
            { clientId: this.state.clientId }
          ),
          search:
            "?page=" +
            this.state.pageNo +
            "&page_size=" +
            this.state.pageSize +
            "&filter=" +
            this.state.filter.find((f) => f.is_selected === true).id +
            queryParams,
        });
    } else {
      this.props.history &&
        this.props.history.push({
          //for unit testing in jest (this.props.history &&) Use
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE,
            {
              clientId: this.state.clientId,
            }
          ),

          search:
            "?filter=" +
            this.state.filter.find((f) => f.is_selected === true).id +
            queryParams,
        });
    }
  };

  isFormValid = () => {
    let formError = false;
    let boardNameError = false;
    if (this.state.boardName.trim() === "") {
      boardNameError = true;
      formError = true;
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        boardNameError: boardNameError,
      },
      apiError: null,
    });
    return formError;
  };

  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let boardId = this.state.boardId;
          let prepareObject = {
            name: this.state.boardName,
          };
          if (boardId === null) {
            //create
            BoardServices.addBoard(prepareObject)
              .then((res) => {
                this.setState(
                  {
                    boardModal: false,
                    rowData: [],
                    rowDatacards: [],
                    pageNo: 1,
                  },
                  () => {
                    this.saveBoardSuccess();
                  }
                );
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          } else {
            BoardServices.editBoard(boardId, prepareObject)
              .then((res) => {
                this.setState(
                  {
                    boardModal: false,
                    rowData: [],
                    rowDatacards: [],
                  },
                  () => {
                    this.saveBoardSuccess();
                  }
                );
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          }
        }
      }
    );
  };
  saveBoardSuccess = () => {
    let boardId = this.state.boardId ? this.state.boardId : null;
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Success</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {boardId === null
              ? constants.MY_BOARDS.CREATE.SUCCESS
              : constants.MY_BOARDS.EDIT.SUCCESS}
          </h2>
        </div>
      </>
    );
    this.setState(
      {
        dailogModal: true,
        dailogModalHeader: dailogModalHeader,
        dailogModalContent: dailogModalContent,
        dailogModalConfig: { type: false, id: null },
        dailogModalStyleType: "success-modal",
      },
      this.getBoards
    );
  };
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  closeShareBoardModal = () => {
    this.setState({
      sharedBoardId: null,
    });
  };

  openShareBoardModal = (e, data) => {
    e.stopPropagation();
    this.setState({
      sharedBoardId: data.id,
    });
  };

  handleFilterChange = (e) => {
    let filters = this.state.filter;
    filters.map((f) => (f.is_selected = f.id === e.id));
    this.setState(
      {
        filter: filters,
        rowData: [],
        rowDatacards: [],
        pageNo: 1,
      },
      () => {
        this.getBoards();
      }
    );
  };

  handleNavTabChange = (id) => {
    let navtabs = this.state.navTabs;
    navtabs.map((f) => (f.is_selected = f.id === id));
    this.setState(
      {
        navTabs: navtabs,
        rowData: [],
        rowDatacards: [],
        pageNo: 1,
      },
      () => {
        this.getBoards();
      }
    );
  };

  saveShareBoardSuccess = (boardId, shared_users) => {
    Toast(constants.MY_BOARDS.SHARE.SUCCESS, "success");
    this.setState(
      {
        sharedBoardId: null,
      },
      async () => {
        this.getBoards();
        let updatedboard;
        await BoardServices.getBoardbyId(boardId).then((res) => {
          updatedboard = res.data;
        });

        this.updateBoardLocally(constants.MY_BOARDS.SHARED_WITH_USERS, {
          id: boardId,
          updatedboard,
        });
      }
    );
  };

  findupdateduser = (owner, shared_users, updated_user_id) => {
    let users = [owner, ...shared_users];
    let updated_user_name = users.map((user) => {
      if (user.id === updated_user_id) {
        return user.first_name + user.last_name;
      }
    });
    return updated_user_name[0] != undefined
      ? updated_user_name[0]
      : owner.first_name + " " + owner.last_name;
  };

  findAsActions = (owner, shared_users, user_id) => {
    let users = [owner, ...shared_users];
    let has_access = users.filter((user) => user.id === user_id);
    return has_access.length > 0 ? true : false;
  };

  enableDisableCommentsHandler = (e, board) => {
    e.stopPropagation();
    let body = {
      display_comments: board.display_comments === 0 ? 1 : 0,
    };
    BoardServices.editBoard(board.id, body)
      .then(async (response) => {
        if (this.state.clickgrid) {
          // in grid view we are not making an api call, instead changing the card state
          let updatedboard;
          await BoardServices.getBoardbyId(board.id).then((res) => {
            updatedboard = res.data;
          });
          this.updateBoardLocally(constants.MY_BOARDS.ENABLE_DISABLE_COMMENTS, {
            id: board.id,
            updatedboard,
          });
        } else {
          this.getBoards();
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  changelistgrid = () => {
    let sortColumn = this.state.sortColumn;
    if (this.state.clickgrid) {
      sortColumn = constants.PAGINATION.DEFAULT_SORT_COLUMN;
    } else {
      sortColumn = "updated_at";
    }
    this.setState({
      clickgrid: !this.state.clickgrid,
      rowData: [],
      rowDatacards: [],
      pageNo: 1,
      sortColumn,
    });
  };

  // update local state of boards
  // currently handling share and enableDisableComments functionality only...
  updateBoardLocally = (type, data) => {
    let rowDataCards = [];
    let boardindex;
    if (type === constants.MY_BOARDS.ENABLE_DISABLE_COMMENTS) {
      rowDataCards = this.state.rowDatacards.filter(
        (card) => card.id !== data.id
      );
      rowDataCards.unshift(data.updatedboard);
    } else if (type === constants.MY_BOARDS.DELETE_BOARD) {
      rowDataCards = this.state.rowDatacards.filter(
        (card) => card.id !== data.boardId
      );
    } else {
      rowDataCards = this.state.rowDatacards.map((card) => {
        if (card.id === data.id) {
          return data.updatedboard;
        }
        return card;
      });
    }
    this.setState({
      rowDatacards: rowDataCards,
    });
  };

  navTabsButton = (listId) => {
    this.state.navTabs.forEach((list, index) => {
      list.is_selected = false;
      if (listId === list.id) {
        list.is_selected = true;
      }
    });
  };

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <h1 className="text-ligntblue2 font-weight-bold mb-0">
              {constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME}
            </h1>
            <div className="ml-3 d-flex align-items-center">
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MY_BOARDS_RESOURCE.TITLE}
                action={constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.CREATE}
              >
                <aside
                  tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                  className=""
                >
                  <button
                    className={
                      "btn btn-md btn-primary d-flex align-items-center icon-text-primary-btn"
                    }
                    type="button"
                    data-testid="btnCreateBoard"
                    onClick={() => {
                      this.createBoard(null);
                    }}
                  >
                    <span className="material-icons-round icon-fs-14 mr-1">
                      add
                    </span>
                    {" " +
                      constants.APPLICATION_ROUTE.MY_BOARDS.LIST
                        .ADD_BUTTON_TITLE}
                  </button>
                </aside>
              </AclAction>
              <div className="segments-card-wrapper btn-items-card-wrapper bg-transparent m-0">
                <div
                  ref={this.divRef}
                  className={
                    "custom-card segments-card btn-items-card open-card"
                  }
                >
                  <ul className="ml-4 segment-items btn-items d-flex flex-wrap">
                    {this.state.navTabs.map((list, index) => (
                      <li
                        key={list.id}
                        className={`${
                          list.is_selected === true ? "active" : ""
                        }`}
                      >
                        <div
                          className={`content font-weight-semibold d-flex align-items-center mr-2`}
                          onClick={() => {
                            this.handleNavTabChange(list.id);
                          }}
                        >
                          <span>{list.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="ml-auto d-flex align-items-center">
              <div className="border-search search-box-shadow-2">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Search Board"}
                />
              </div>

              <div
                className="ml-4"
                data-for="view"
                data-tip={!this.state.clickgrid ? "Grid View" : "List View"}
              >
                <button
                  className="icon-btn-white icon-btn icon-box-shadow d-inline-flex align-items-center justify-content-center"
                  data-testid="btnTest"
                  onClick={() => this.changelistgrid()}
                >
                  <span className="material-icons-outlined icon">
                    {!this.state.clickgrid
                      ? "grid_view"
                      : "format_list_bulleted"}
                  </span>
                  <CustomReactTooltip id="view" multiline={true} place="top" />
                </button>
              </div>

              <div className="ml-3 help-icon-box-shadow">
                <HelpModal category="My Boards List" />
              </div>
            </div>
          </div>
          {this.state.clickgrid && (
            <div className="card my-boards-wrapper-card" data-testid="card">
              <div className="card-body my-borads-card-body">
                <div className="row xl-gutter">
                  {this.state.rowDatacards.map((b, i) => (
                    <div className={`col-12 col-sm-6 col-lg-4 mb-3`} key={b.id}>
                      <BoardCard
                        footer="true"
                        key={b.id}
                        board={b}
                        editHandler={this.editHandler}
                        deleteHandler={this.removeBoardConfirmationHandler}
                        detailsPageHandler={this.detailsPageHandler}
                        shareHandler={this.openShareBoardModal}
                        enableDisableCommentsHandler={
                          this.enableDisableCommentsHandler
                        }
                        updated_by_user={this.findupdateduser(
                          b.owner,
                          b.shared_users,
                          b.updated_by
                        )}
                        boardDeatils={this.findAsActions(
                          b.owner,
                          b.shared_users,
                          tokenDetails.user?.id
                        )}
                      />
                    </div>
                  ))}
                  <div className="col-12">
                    {this.state.rowData &&
                      this.state.rowDatacards.length === 0 && (
                        <div className={"col-sm-12 text-center no-boards"}>
                          <span className="material-icons-round mr-2 icon-fs-18">
                            report_problem
                          </span>
                          <h5>
                            {
                              constants.APPLICATION_ROUTE.MY_BOARDS.LIST
                                .NO_BOARDS_FOUND
                            }
                          </h5>
                        </div>
                      )}
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  {this.state.rowDatacards.length > 9 && (
                    <div className="see-more-btn mx-4 px-2 d-flex flex-column align-items-center justify-content-center">
                      <div
                        className="d-flex justify-content-center cursor-pointer flex-column align-items-center"
                        onClick={() => this.handleExpandLess()}
                      >
                        <h3 className="text-ligntgrey mb-0">See Less</h3>
                        <span className="material-icons-round text-darkblue">
                          expand_less
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.rowData.length != 0 &&
                    this.state.rowDatacards.length != this.state.count && (
                      <div className="see-more-btn mx-4 px-2 d-flex flex-column align-items-center justify-content-center">
                        <div
                          className="d-flex justify-content-center cursor-pointer flex-column align-items-center"
                          onClick={() => {
                            this.handlePage(this.state.pageNo + 1);
                          }}
                        >
                          <h3 className="text-ligntgrey mb-0">See More</h3>
                          <span className="material-icons-round text-darkblue">
                            expand_more
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
          {!this.state.clickgrid && (
            <div
              data-testid="table"
              className="w-100 custom-table-block custom-table-block-2 projects-list-table-block sort-table-block th-nowrap center-th-align"
            >
              <DynamicTableComponent
                columnData={tableHeader}
                rowData={this.state.rowData}
                pagePrevious={this.pagePrevious}
                pageNext={this.pageNext}
                pageSize={this.state.pageSize}
                pageNo={this.state.pageNo}
                handlePage={this.handlePage}
                handleSort={this.handleSort}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                count={this.state.count}
                rowAction={this.rowAction}
                colSpan={tableHeader.length}
                showActions={showActions}
                table={{ className: "client_user" }}
                showPagination={true}
                handlePageSize={this.handlePageSize}
                paginationClass={"center-pagination custom-pagination-new"}
              />
            </div>
          )}

          <Modal
            isOpen={this.state.boardModal}
            toggle={this.closeBoardModal}
            className={"modal-md modal-w-header custom-modal"}
          >
            <ModalHeader toggle={this.closeBoardModal}>
              {this.state.boardModalHeader}
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <form className={"col-sm-12 form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className={"row"}>
                    <div className="col-sm-12 form-group position-relative">
                      <label htmlFor="boardName">
                        Name <span className={"mandatory"}>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          this.state.formErrors.boardNameError === true
                            ? "is-invalid"
                            : ""
                        } ${
                          this.state.formErrors.boardNameError === false
                            ? ""
                            : ""
                        }`}
                        id="boardName"
                        placeholder="Board Name"
                        value={this.state.boardName}
                        onChange={this.onBoardNameChangeHandler}
                      />
                      {this.state.formErrors.boardNameError && (
                        <div className="invalid-tooltip">
                          {constants.FORM.MANDATORY_FIELD}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className="modal-btn btn-outline-primary"
                onClick={this.closeBoardModal}
              >
                Cancel
              </Button>
              <Button
                className="modal-btn"
                color="primary"
                onClick={this.handleSubmit}
              >
                {this.state.boardId === null ? `Save` : `Update`}
              </Button>{" "}
            </ModalFooter>
          </Modal>
          {this.state.sharedBoardId && (
            <ShareBoard
              boardId={this.state.sharedBoardId}
              boardDetails={this.state.rowDatacards.find(
                (b) => b.id === this.state.sharedBoardId
              )}
              closeShareBoardModal={this.closeShareBoardModal}
              saveShareBoardSuccess={this.saveShareBoardSuccess}
            />
          )}
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    boardType: (type) => {
      dispatch(boardType(type));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
    board: state.board,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBoards);
