import React, { Component } from "react";
import TimeSince from "../Utils/TimeSince";
import AclAction from "../Components/ACL/AclAction";
import constants from "../Utils/constants";
import permissionCheck from "../lib/permissionCheck";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import decodeJWT from "../lib/decodeJWT";
import ellipsisName from "../Utils/AddEllipsisToName";

class BoardCard extends Component {
  state = {
    showActions: this.props.board.showActions
      ? this.props.board.showActions
      : false,
  };
  componentDidMount() {}
  toggleActions = (e) => {
    e.stopPropagation();
    this.setState({
      showActions: !this.state.showActions,
    });
  };
  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let board = this.props.board;
    let aclPermissionKey = constants.ACL.PERMISSION_KEY;
    let aclResource = constants.ACL.MY_BOARDS_RESOURCE;
    if (
      this.props.board.type === "shared" &&
      this.props.board.mode != null &&
      this.props.board.mode != 0 &&
      this.props.board.mode.short_name != undefined
    ) {
      switch (this.props.board.mode.short_name) {
        case constants.MY_BOARDS_SHARE_MODE.EDIT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_EDIT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
        case constants.MY_BOARDS_SHARE_MODE.COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_COMMENT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW_COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW_COMMENT;
          break;
        default:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
      }
    }

    let actionsEnabled =
      permissionCheck(
        aclPermissionKey,
        aclResource.TITLE,
        aclResource.ACTIONS.EDIT
      ) ||
      permissionCheck(
        aclPermissionKey,
        aclResource.TITLE,
        aclResource.ACTIONS.DELETE
      ) ||
      permissionCheck(
        aclPermissionKey,
        aclResource.TITLE,
        aclResource.ACTIONS.SHARE
      );
    let detailsPageAccess = permissionCheck(
      aclPermissionKey,
      aclResource.TITLE,
      aclResource.ACTIONS.DASHBOARD
    );
    let isShareAccess = permissionCheck(
      aclPermissionKey,
      aclResource.TITLE,
      aclResource.ACTIONS.SHARE
    );
    return (
      <div className="">
        <div
          className={`card my-boards-card ${
            this.props.board.owner.id == tokenDetails.user?.id
              ? ""
              : "shared-board"
          } ${this.state.showActions ? "active" : ""}`}
        >
          <div
            className="card-body my-borads-card-body cursor-pointer"
            onClick={() => {
              detailsPageAccess && this.props.detailsPageHandler(board);
            }}
          >
            <div className="d-flex align-items-center w-100">
              <div className="mr-2">
                <h3
                  className="card-title text-black2 mb-10px font-weight-semibold"
                  data-for="boardName"
                  data-tip={board.name}
                >
                  {board.name.length > 75
                    ? `${board.name.slice(0, 75)}...`
                    : board.name}
                </h3>
                <CustomReactTooltip id="boardName" multiline={true} />
                {this.props.footer && (
                  <>
                    <p className="mb-0">
                      <span className="text-ligntgrey">Last Updated :</span>
                      <span className="text-black2">
                        {" "}
                        {TimeSince(board.updated_at)}
                      </span>
                    </p>
                    <p className="mb-0">
                      <span className="text-ligntgrey">
                        Content Last Updated :
                      </span>
                      <span className="text-black2">
                        {" "}
                        {board.total_pinned_content_count === 0
                          ? "-"
                          : TimeSince(board.last_updated_at)}
                      </span>
                    </p>
                    {/* For Hiding this content last updated field if pin count is 0*/}
                    {/* {board.total_pinned_content_count === 0 ? null : (
                      <p className="mb-0">
                      <span className="text-ligntgrey">Content Last Updated :</span>
                      {" "}
                      <span className="text-black2">{TimeSince(board.last_updated_at)}</span>
                      </p>
                    )} */}
                  </>
                )}
                <p className="mb-0">
                  <span className="text-ligntgrey">By : </span>
                  <span
                    className="text-black2"
                    data-for="overflowName"
                    data-tip={
                      this.props.board.owner.display_name ||
                      this.props.board.owner.first_name
                    }
                  >
                    {ellipsisName(this.props.board.owner.display_name) ||
                      ellipsisName(this.props.board.owner.first_name)}
                  </span>
                  <CustomReactTooltip id="overflowName" />
                </p>
              </div>
              {this.props.footer && (
                <div className="pin-items-block text-white text-center ml-auto d-flex flex-column">
                  <h2 className="font-weight-normal text-white mb-0">
                    {board.total_pinned_content_count
                      ? board.total_pinned_content_count
                      : 0}
                  </h2>
                  <span className="fs-12">PINS</span>
                </div>
              )}
            </div>
            {actionsEnabled && <></>}
          </div>

          <div className="card-footer">
            <div className="d-flex w-100">
              {actionsEnabled && this.props.boardDeatils && (
                <div className="actions_board w-100 d-flex">
                  <ul className="d-flex align-items-center justify-content-evenly w-100">
                    <span
                      className="d-inline-flex"
                      data-for={`tooltip-display`}
                      data-tip={
                        board.display_comments === 1
                          ? "Disable Comments"
                          : "Enable Comments"
                      }
                      data-iscapture="true"
                    >
                      <label className="custom-switch-checkbox">
                        <input
                          type="checkbox"
                          checked={board.display_comments === 1}
                        />
                        <span
                          data-testid="btnTestBoardToggle"
                          className="slider round"
                          onClick={(e) => {
                            this.props.enableDisableCommentsHandler(e, board);
                          }}
                        ></span>
                      </label>
                    </span>
                    <CustomReactTooltip
                      id={`tooltip-display`}
                      multiline={true}
                    />
                    <AclAction
                      type={aclPermissionKey}
                      entity={aclResource.TITLE}
                      action={aclResource.ACTIONS.SHARE}
                    >
                      <li
                        data-testid="btnTestBoardShare"
                        className="d-inline-flex cursor-pointer"
                        tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                        onClick={(e) => {
                          this.props.shareHandler(e, board);
                        }}
                        data-for="shareboard"
                        data-tip="Share Board"
                      >
                        <span className="material-icons-outlined text-blue-clr icon-fs-20">
                          share
                        </span>
                        <CustomReactTooltip id="shareboard" multiline={true} />
                      </li>
                    </AclAction>
                    <AclAction
                      type={aclPermissionKey}
                      entity={aclResource.TITLE}
                      action={aclResource.ACTIONS.EDIT}
                    >
                      <li
                        data-testid="btnTestBoardEdit"
                        className="d-inline-flex cursor-pointer"
                        tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                        onClick={(e) => {
                          this.props.editHandler(e, board);
                        }}
                        data-for="editboard"
                        data-tip="Edit Board"
                      >
                        <span class="material-icons-outlined text-blue-clr icon-fs-20">
                          edit
                        </span>
                        <CustomReactTooltip id="editboard" multiline={true} />
                      </li>
                    </AclAction>
                    <AclAction
                      type={aclPermissionKey}
                      entity={aclResource.TITLE}
                      action={aclResource.ACTIONS.DELETE}
                    >
                      <li
                        data-testid="btnTestBoardDelete"
                        className="d-inline-flex cursor-pointer"
                        tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                        onClick={(e) => {
                          this.props.deleteHandler(e, board);
                        }}
                        data-for="deleteboard"
                        data-tip="Delete Board"
                      >
                        <span class="material-icons-outlined error-clr icon-fs-20">
                          delete
                        </span>
                        <CustomReactTooltip id="deleteboard" multiline={true} />
                      </li>
                    </AclAction>
                  </ul>
                </div>
              )}
              <div
                className={`${
                  actionsEnabled ? "border-left" : ""
                } action-icon flex-grow-1 d-inline-flex justify-content-center`}
              >
                <span
                  className="material-icons-round icon cursor-pointer flex-grow-1 text-center"
                  onClick={() => {
                    detailsPageAccess && this.props.detailsPageHandler(board);
                  }}
                >
                  arrow_forward
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.props.board.type === "owner" &&
          this.props.board.shared_users.length > 0 && (
            <p className="align-items-center d-flex justify-content-end mt-1 mb-0 share-info text-right">
              Shared with
              {board.is_shared_with_all && (
                <b
                  className="ml-1"
                  onClick={(e) => {
                    isShareAccess && this.props.shareHandler(e, board);
                  }}
                >
                  ALL
                </b>
              )}
              {this.props.board.shared_users &&
                this.props.board.shared_users.length > 0 && (
                  <span
                    className="ml-1 d-flex"
                  >
                    {this.props.board.shared_users.slice(0, 3).map((s, i) => (
                      <>
                        <span
                          className="material-icons-round icon-fs-16"
                          key={i}
                          aria-hidden="true"
                          data-for={`tooltip-${Slugify(s.email)}`}
                          data-tip={`${s.first_name} ${s.last_name} (${s.email})`}
                          data-iscapture="true"
                        >
                          person
                        </span>

                        <CustomReactTooltip
                          id={`tooltip-${Slugify(s.email)}`}
                          multiline={true}
                        />
                      </>
                    ))}
                  </span>
                )}
            </p>
          )}
      </div>
    );
  }
}
export default BoardCard;
