import React, { useEffect, useState } from "react";
import constants from "../Utils/constants";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import { useHistory } from "react-router-dom";
import { HeaderNotificationService } from "../Services/HeaderNotification";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  notifications,
  notificationItem,
} from "../Store/Actions/Notifications";
import Toast from "../Utils/Toast";

function HeaderNotification() {
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationList = useSelector(
    (state) => state.notification.notifications
  );

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleMarkReadAll = async () => {
    try {
      let input = {
        ids: notificationList.filter((i) => !i.is_read).map((item) => item.id),
      };
      await HeaderNotificationService.markReadAll(input);
      await getNotificationList();
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const markAsRead = async (id, e) => {
    try {
      e.stopPropagation();

      let input = {
        ids: [id],
      };
      await HeaderNotificationService.markReadAll(input);
      await getNotificationList();
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const handleNotification = async (e, id, boardId, isRead, meta_data) => {
    
    try {
      e.stopPropagation();
      if (!isRead) {
        let input = {
          ids: [id],
        };
        await HeaderNotificationService.markReadAll(input);
        await getNotificationList();
      }
      togglePopover();
      dispatch(notificationItem(JSON.parse(meta_data)));

      history.push(`/board/${boardId}/dashboard`);
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };
  const socketUrl = process.env.REACT_APP_WEB_SOCKET_URL;
  // Replace with your WebSocket URL

  const getNotificationList = async () => {
    try {
      let list = await HeaderNotificationService.getNotificationList();

      dispatch(notifications(list?.data?.notifications || []));
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  useEffect(() => {
    const socket = new WebSocket(socketUrl);

    // Event listeners
    socket.onopen = () => console.log("WebSocket connection opened");
    socket.onmessage = (event) => {
      let msg = event.data;
      if (msg === "NEW_NOTIFICATION_BOARDS") {
        getNotificationList();
      }
    };
    socket.onerror = (error) => console.error("WebSocket error:", error);
    socket.onclose = () => console.log("WebSocket connection closed");

    // Cleanup on unmount
    return () => {
      socket.close();
    };
  }, [socketUrl]);

  return (
    <li className="mr-3">
      <Button
        id="PopoverLegacy-notification"
        style={{
          padding: 0,
          border: "none",
          background: "none",
          boxShadow: "none",
        }}
        type="button"
        className="d-inline-flex"
        data-testid="popover"
      >
        <img
          src={
            "https://tbr-ggk.s3.us-east-2.amazonaws.com/development+/NotificationIcon.svg"
          }
          alt="Notification"
          className="cursor-pointer"
        />
        {notificationList.filter((i) => !i.is_read).length > 0 && (
          <span
            className="notification-count"
            style={
              Math.floor(
                notificationList.filter((i) => !i.is_read).length / 10
              ) == 0
                ? {
                    fontSize: "11px",
                    width: "13px",
                    height: "13px",
                  }
                : {
                    fontSize: "10px",
                    width: "14px",
                    height: "14px",
                  }
            }
          >
            {notificationList.filter((i) => !i.is_read).length}
          </span>
        )}
      </Button>

      <UncontrolledPopover
        placement="bottom"
        target="PopoverLegacy-notification"
        trigger="legacy"
        className="custom-popover"
        style={{
          width: "280px",
          background: "var(--color-gray-10, #FFF)",
          top: "15px",
          position: "absolute",
          right: "-140px",
        }}
        isOpen={popoverOpen}
        toggle={togglePopover}
      >
        {notificationList.length ? (
          <>
            <PopoverBody>
              {notificationList.filter((i) => !i.is_read).map((item) => item.id)
                .length > 0 && (
                <sapn
                  className="mark-read-all-notification"
                  style={{ cursor: "pointer" }}
                  onClick={handleMarkReadAll}
                >
                  Mark read all
                </sapn>
              )}
              <div className="notification-main-div">
                {notificationList.slice(0, 4).map((item, index) => (
                  <div
                    key={item.id} // Added key here for React rendering
                    className="notification-div"
                    onClick={(e) =>
                      handleNotification(
                        e,
                        item?.id,
                        item.board_id,
                        item.is_read,
                        item?.meta_data
                      )
                    }
                    style={{
                      background: !item.is_read ? "#EEF6FF" : "none",
                      cursor: "pointer",
                    }}
                  >
                    <div className="div-1">
                      <img
                        src={
                          "https://tbr-ggk.s3.us-east-2.amazonaws.com/MassageIcon.svg"
                        }
                        alt="pin-icon"
                      />
                      <div>
                        <span className="span-1">{item.title || ""} </span>
                        <span className="span-2">{item.message}</span>
                        <span className="span-3">
                          {moment(item.created_at).format(
                            "MMM DD, YYYY , HH:mm:ss"
                          )}
                          {!item.is_read && (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={(e) => markAsRead(item.id, e)}
                            >
                              Mark as read
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverBody>
            <div className="view-all-btn-div">
              <Button
                className="btn btn-primary"
                onClick={() => {
                  history.push(
                    constants.APPLICATION_ROUTE.NOTIFICATIONS.LIST.ROUTE
                  );
                  togglePopover();
                }}
              >
                View All
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{
              height: "50px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            No notifications yet!
          </div>
        )}
      </UncontrolledPopover>
    </li>
  );
}

export default HeaderNotification;
