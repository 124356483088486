import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/downloadProgress";

const downloadingProgressModel = DETAILS_REF.downloadingProgressModel;
const downloadFileDetails = DETAILS_REF.downloadFileDetails;
const downloadingProgress = DETAILS_REF.downloadingProgress;
const downloadingProgressId = DETAILS_REF.downloadingProgressId;

const downloadingProcessModel = combineReducers({
  downloadingProgressModel,
  downloadFileDetails,
  downloadingProgress,
  downloadingProgressId
});

export default downloadingProcessModel;
