import React, { Component } from "react";
import AclAction from "../Components/ACL/AclAction";
import constants from "../Utils/constants";
import TimeSince from "../Utils/TimeSince";
import { connect } from "react-redux";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
class TemplateCard extends Component {
  getDisplayName(template) {
    let displayName = "------";
    if (template.updated_by_user) {
      const detail = template.updated_by_user;
      displayName = detail.display_name || detail.first_name;
    } else if (template.created_by_user) {
      const detail = template.created_by_user;
      displayName = detail.display_name || detail.first_name;
    }
    return displayName;
  }

  render() {
    let template = this.props.template;
    let displayName = this.getDisplayName(template);
    let id = this.props.template && this.props.template.id;
    let aclPermissionKey = constants.ACL.PERMISSION_KEY;
    let aclResource = constants.ACL.MY_BOARDS_RESOURCE;
    let isCreatedUser =
      this.props.home &&
      this.props.home.loginUserSuccess &&
      this.props.home.loginUserSuccess?.id ===
        this.props.template?.created_by_user?.id;
    return (
      <>
        <div className={`card template-board my-boards-card my-templates-card`}>
          <div className='card-body my-borads-card-body my-templates-card-body'>
            <div className='card-title template-title mb-0 d-flex align-items-center'>
              {this.props.template &&
              this.props.template.chart_type === "line" ? (
                <span
                  className='material-icons-outlined text-grey mr-2'
                  style={{ fontSize: "1.5rem" }}
                >
                  stacked_line_chart
                </span>
              ) : this.props.template &&
                this.props.template.chart_type === "bubble" ? (
                <span
                  className='material-icons-outlined text-grey mr-2'
                  style={{ fontSize: "1.5rem" }}
                >
                  bubble_chart
                </span>
              ) : (
                <span
                  className='material-icons-outlined text-grey mr-2'
                  style={{ fontSize: "1.5rem" }}
                >
                  bar_chart
                </span>
              )}
              {this.props.template.name.length > 87 ? (
                <h3
                  className='card-title text-black2 font-weight-semibold my-0 text rct-title mb-0'
                  key={this.props.template.name}
                  htmlFor={this.props.template.name}
                  data-for={`tooltip-${this.props.template.name}-active`}
                  data-tip={this.props.template.name}
                >
                  {this.props.template.name.substring(0, 87) + "..."}
                  <CustomReactTooltip
                    id={`tooltip-${this.props.template.name}-active`}
                    multiline={true}
                  />
                </h3>
              ) : (
                <h3
                  className='card-title text-black2 font-weight-semibold my-0 text rct-title mb-0'
                  key={this.props.template.name}
                  htmlFor={this.props.template.name}
                >
                  {this.props.template.name}
                </h3>
              )}
            </div>
            <p className='mb-1 mt-2'>
              <span className='text-ligntgrey'>Last Updated : </span>
              <span className='text-black2'>
                {TimeSince(
                  this.props.template.updated_at
                    ? this.props.template.updated_at
                    : ""
                )}
              </span>
            </p>
            <p className='mb-0'>
              <span className='text-ligntgrey'>By : </span>
              {displayName.length > 30 ? (
                <span
                  className='text-black2'
                  key={displayName}
                  htmlFor={displayName}
                  data-for={`tooltip-${displayName}-active`}
                  data-tip={displayName}
                >
                  {displayName.substring(0, 30) + "..."}
                  <CustomReactTooltip
                    id={`tooltip-${displayName}-active`}
                    multiline={true}
                  />
                </span>
              ) : (
                <span className='text-black2'>{displayName}</span>
              )}
            </p>
          </div>
          <div className='card-footer'>
            <div className='d-flex justify-content-end'>
              {isCreatedUser && (
                <div className='actions_board d-flex align-items-center justify-content-evenly w-100'>
                  <AclAction
                    type={aclPermissionKey}
                    entity={aclResource.TITLE}
                    action={aclResource.ACTIONS.EDIT}
                  >
                    <div
                      className='d-inline-flex cursor-pointer align-item-center'
                      tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                      onClick={(e) => {
                        this.props.shareHandler(e, template);
                      }}
                      data-for='sharetemplate'
                      data-tip='Share Visualization'
                    >
                      <span className='material-icons-outlined icon text-blue-clr icon-fs-20'>
                        share
                      </span>
                      <CustomReactTooltip id='sharetemplate' multiline={true} />
                    </div>
                  </AclAction>
                  <AclAction
                    type={aclPermissionKey}
                    entity={aclResource.TITLE}
                    action={aclResource.ACTIONS.EDIT}
                  >
                    <div
                      className='d-inline-flex cursor-pointer align-item-center'
                      tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                      onClick={(e) => {
                        this.props.editHandler(e, id);
                      }}
                      data-for='edittemplate'
                      data-tip='Edit Visualization'
                    >
                      <span className='material-icons-outlined icon text-blue-clr icon-fs-20'>
                        edit
                      </span>
                      <CustomReactTooltip id='edittemplate' multiline={true} />
                    </div>
                  </AclAction>
                  <AclAction
                    type={aclPermissionKey}
                    entity={aclResource.TITLE}
                    action={aclResource.ACTIONS.DELETE}
                  >
                    <div
                      className=' d-inline-flex cursor-pointer align-item-center'
                      tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                      onClick={(e) => {
                        this.props.deleteHandler(e, template);
                      }}
                      data-for='deletetemplate'
                      data-tip='Delete Visualization'
                    >
                      {/* <i className="fa fa-trash" /> */}
                      <span className='material-icons-outlined icon error-clr icon-fs-20'>
                        delete
                      </span>
                      <CustomReactTooltip
                        id='deletetemplate'
                        multiline={true}
                      />
                    </div>
                  </AclAction>
                </div>
              )}
              <div
                className={`border-left action-icon flex-grow-1 d-inline-flex justify-content-center`}
                onClick={(e) => {
                  this.props.detailsPageHandler(e, id);
                }}
              >
                <span className='material-icons-round icon cursor-pointer'>
                  arrow_forward
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.props.template?.shared_users.length > 0 && isCreatedUser && (
          <p className='align-items-center d-flex justify-content-end mt-1 share-info text-right'>
            Shared with
            <span
              className='ml-1'
              onClick={(e) => {
                this.props.shareHandler(e, template);
              }}
            >
              {this.props.template.shared_users.slice(0, 3).map((s, i) => (
                <>
                  <span
                    className='material-icons-round icon-fs-16'
                    key={i}
                    aria-hidden='true'
                    data-for={`tooltip-${Slugify(s.email)}`}
                    data-tip={`${s.first_name} ${s.last_name} (${s.email})`}
                    data-iscapture='true'
                  >
                    person
                  </span>

                  <CustomReactTooltip
                    id={`tooltip-${Slugify(s.email)}`}
                    multiline={true}
                  />
                </>
              ))}
            </span>
          </p>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(mapStateToProps, null)(TemplateCard);
