import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/notification";

const notifications = DETAILS_REF.fetchNotification;
const notificationMetadata = DETAILS_REF.fetchNotioficationMetadata;

const notificationReducer = combineReducers({
  notifications,
  notificationMetadata
});

export default notificationReducer;
